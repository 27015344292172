@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@font-face {
	font-family: 'Rubik mono';
	src: local('RubikMonoOne'), url(./fonts/RubikMonoOne-Regular.ttf) format('truetype');
  }

html {
	width: 100%;
	height: 100%;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	width: 100%;
	height: 100%;
	font-family: 'Open Sans', sans-serif;
}

#root {
	height: 100%;
}
