@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@font-face {
	font-family: 'Rubik mono';
	src: local('RubikMonoOne'), url(../../static/media/RubikMonoOne-Regular.bca8f501.ttf) format('truetype');
  }

html {
	width: 100%;
	height: 100%;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	width: 100%;
	height: 100%;
	font-family: 'Open Sans', sans-serif;
}

#root {
	height: 100%;
}

#jumbotronVideo {

}
.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
.App {
    display: flex;
    justify-content: center;
  }
  input {
    display: block;
    width: 20rem;
    height: 2rem;
    padding: 0.5rem;
    font-size: 1.1em;
    font-weight: 500;
    margin-bottom: 2rem;
  }
  button {
    border: none;
    padding: 1rem;
    width: 21.2rem;
    font-size: 1.2em;
    border-radius: 0.2rem;
    cursor: pointer;
  }
  button:hover {
    background-color: #c5c5c5;
  }
  .message {
    font-size: 1.2em;
    text-align: center;
    color: #36a936;
  }
  fieldset {
    width: 125px;
}
.item {
    display: block;
    width: 50px;
}
label {
    display: inline;
}
input[type=checkbox] {
    display: inline;
}

